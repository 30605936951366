import Vue from 'vue'
import App from './App.vue'
import router from "./router/router"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/iconfont/iconfont.css"
import *as api from './api'

import VueQuillEditor from 'vue-quill-editor'
// require styles 引⼊样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
import Meta from "vue-meta";
Vue.use(Meta);

import topNav from "./components/topNav.vue";
import bottomNav from "./components/bottomNav.vue";
Vue.component("topNav", topNav)
Vue.component("bottomNav", bottomNav)
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.directive('title',{
  inserted: function(el){
      document.title = el.getAttribute('title')
      console.log(el)
  }
})
new Vue({
  router,
  render: h => h(App),
  beforeCreate: function () {
    Vue.prototype.$bus = this
    Vue.prototype.$api = api
  },
  metaInfo: {
    title: "爱泡饭-专业二手苹果电商平台",
    meta: [
      { name: "keywords", content: "爱泡饭，爱泡饭商城，二手苹果自营，二手手机，二手电脑，二手平板，二手笔记本，二手智能手表，二手真无线耳机，二手配件，二手iPhone，二手Mac，二手iMac，二手Apple Watch，二手AirPods。" },
      { name: "description", content: "爱泡饭是广州市正其品网络科技有限公司旗下二手优品自营平台，为果粉提供原装正品二手Apple设备，支持七天无理由退货，180天质保服务，找靓机就选爱泡饭。" },
    ],
  },

}).$mount('#app')
