<template>
  <div>
                 <!-- 底部关于我们 -->
        <div class="about_us">
          <div class="about_us_icon">
        <img src="../.../../assets/images/aipaofanlogo.png" alt="">

          </div>
        <div class="new_phone">
          <img src="../.../../assets/images/ganbizhiliangganbijiage.png" alt="" style="height:80%">
        </div>
        <div class="download">
          <div class="download_img">
             <img src="../.../../assets/images/dianjijingruaipaofanshangcheng.png" alt="" style="height:80%">
          <a href="http://s.pdb2.com/l/1mkeEj28e2gBBMS" target="_blank" class="towxapp">
            
          </a>
          </div>
        </div>
        </div>
        <div class="file">
  <p>Copyright  @2022  广州市正其品网络科技有限公司 &nbsp;&nbsp;&nbsp;粤ICP备19049060号</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.about_us{
width: 100%;
height: 150px;
background-color: #4cbcbc;
display: flex;
justify-content: space-between;
align-items: center;
}
.about_us_icon{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 33%;
}
.about_us_icon img{
  margin: 30px;
  height:80%;
}
/* .about_us_icon img:last-child{
  height: 60px;

} */
.new_phone{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
}
.download{
  display: flex;
  /* flex-direction: column; */
  /* margin: 50px; */
  align-items: center;
  justify-content:flex-end;
  height: 100%;
  
  width: 33%;
}
.download_img{
  /* margin-top: 12px;
  margin-right: 30px; */
   height: 100%;
   display: flex;
   align-items: center;
   margin-right: 10%;
   /* margin-top: -20px; */
   position: relative;
}


.download_text{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.download_text div{
height: 25%;
width: 150px;
color: #ffffff;

font-size: 16px;
border: solid #ffffff 1px;
display: flex;
justify-content: center;
align-items: center;

}
.download_text div:first-child{
  margin-bottom: 10%;
}
.download_text  div span{
  margin-right: 20px;
}
.iphone{
  margin-left: -5px;
}
.xungou{
  color: #ffffff;
  border: solid #ffffff 1px;
  width: 70px;
  text-align: center;
  margin-top: -10px;
  margin-left: -5px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.file{
    width: 100%;
    height: 80px;
    background-color: #000000;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
   
    
}
.towxapp{
  position: absolute;

  display: flex;
  width: 100%;
  height: 80%;
 bottom: 11%;
  right: 0;
}
@media screen and (max-width: 1255px) {
  .about_us{
    height: 60px;
  }
    .new_phone{
     display: none;

    }
    .icon2{
     display: none;
    }
    .file{
      font-size: 8px;
      height: 50px;
    }
    .download{
      margin-right: 10%;
    }
    .download_text div{
      /* width: 140px; */
      /* height: 30px; */
      font-size: 8px;
      /* display: flex;
      align-items: center;
      justify-content: center; */
    }
      .download_text div:first-child{
        margin-bottom: 5px;
      }
      .download_img{
        margin-top: 0;

        /* margin-right: 50px; */
      }
      .xungou{
        font-size: 10px;
        width: 50px;
        margin-top: -5px;
      }
}
</style>