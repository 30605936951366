import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

const router = new VueRouter({
    mode: "hash",
    routes: [
        {
            path: '/',
            redirect: { name: 'index' }
        },
        {
            path: "/index",
            component: () => import("../views/index/index.vue"),
            name: "index"
        },
        {
            path: "/news",
            component: () => import("../views/news/news.vue"),
            name: "news"
        },
        {
            path: "/aboutUs",
            component: () => import("../views/aboutUs/aboutUs.vue"),
            name: "abooutUs"
        },
        {
            path: "/contanctUs",
            component: () => import("../views/contanctUs/contanctUs.vue"),
            name: "contanctUs"
        },
        {
            path: "/topNews",
            component: () => import("../views/topNews/topNews.vue"),
            name: "topNews"
        }
    ]
})
export default router