import axios from "axios"

const requests = axios.create({
    baseURL: '/api',

})

// requests.interceptors.request.use((config) => {

//     if (store.state.Detail.uuid_token) {

//         config.headers.userTempId = store.state.Detail.uuid_token
//     }
   
//     if( localStorage.getItem('TOKEN')){
//         config.headers.token= localStorage.getItem('TOKEN')
        
//     }
//     // console.log(store)
//     nprogress.start()
//     return config
// })
// requests.interceptors.response.use((res) => {
//     nprogress.done()
//     return res.data
// }, () => { })

export default requests
