<template>
  <div id="app" v-title title="爱泡饭">
    <!-- 头部导航栏 -->
    <div class="nav">
      <topNav></topNav>
    </div>
    <!-- 中间视图 -->
    <div class="center">
    <router-view></router-view>
    </div>

    <!-- 底部关于我们 -->
    <div class="bottom">
      <bottom-nav></bottom-nav>
    </div>
    <!-- 下载二维码遮罩层 -->

    <div class="popup" v-if="isshow==1" :style="`opacity:${opic}`">
      <div class="popup_download">
        <div class="popup_download_center">
          <img src="./assets/images/5-510x510px-1.jpg" alt="" />
        </div>
        <div class="turnoff hover1" @click="downloadIsShow">X</div>
      </div>
    </div>

        <div class="popup" v-if="isshow==2" :style="`opacity:${opic}`">
      <div class="popup_download">
        <div class="popup_download_center">
          <img src="./assets/images/5-510x510px-2.jpg" alt="" />
        </div>
        <div class="turnoff hover1" @click="downloadIsShow">X</div>
      </div>
    </div>
         <div class="popup" v-if="isshow==3" :style="`opacity:${opic}`">
      <div class="popup_download">
        <div class="popup_download_center">
          <img src="./assets/images/weixintupian_20220428131426.jpg" alt="" />
        </div>
        <div class="turnoff hover1" @click="downloadIsShow">X</div>
      </div>
    </div>
             <div class="popup" v-if="isshow==4" :style="`opacity:${opic}`">
      <div class="popup_download">
        <div class="popup_download_center">
          <img src="./assets/images/aipaofanweixingongzhonghaoerweima.jpg" alt="" />
        </div>
        <div class="turnoff hover1" @click="downloadIsShow">X</div>
      </div>
    </div>
        <div class="popup" v-if="isshow==5" :style="`opacity:${opic}`">
      <div class="popup_download">
        <div class="popup_download_center">
          <img src="./assets/images/aipaofanweixinxiaochengxuerweima.jpg" alt="" />
        </div>
        <div class="turnoff hover1" @click="downloadIsShow">X</div>
      </div>
    </div>
     <div class="popup" v-if="isshow==6" :style="`opacity:${opic}`">
      <div class="popup_download">
        <div class="popup_download_center">
          <img src="./assets/images/zhifubaoxiaochengxu.png" alt="" />
        </div>
        <div class="turnoff hover1" @click="downloadIsShow">X</div>
      </div>
    </div>
  </div>
</template>

<script>
import bottomNav from "./components/bottomNav.vue";

export default {
  components: { bottomNav },
  name: "App",
  data: () => {
    return {
      isshow: 0, //控制二维码是否显示
      images: [  //二维码链接
        "https://s1.huishoubao.com/colagoods/app/static/img/dialog-pic01.d14e58b.png",
        "https://s1.huishoubao.com/colagoods/app/static/img/dialog-pic02.fefffc9.png",
        "https://s1.huishoubao.com/colagoods/app/static/img/dialog-pic03.83f3ac6.png",
        "https://s1.huishoubao.com/colagoods/app/static/img/dialog-pic04.b2d8692.png",
      ],
      nowImage: "", //现在打开的二维码
      opic: 1, //二维码透明度，用于二维码隐藏过渡
    };
  },
  methods: {
    // 显示隐藏下载二维码
    downloadIsShow(a) {
      // this.nowImage = this.images[a];
      if (this.isshow!=0) {
        this.opic = 0;
        setTimeout(() => {
          this.isshow = !this.isshow;
          this.opic=1
        }, 300);
      } else {
        this.isshow = a;
      }
    },
  },
  mounted: function () {
    // 全局事件总线绑定二维码显示隐藏事件
    this.$bus.$on("download", this.downloadIsShow);
  },
};
</script>

<style>

* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

/* ::-webkit-scrollbar {
  display: none;
} */
.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(54, 54, 54, 0.4);
  z-index: 99;
  transition: all 0.3s linear;
display: flex;
justify-content: center;
align-items: center;
}
.popup_download {
  width: 20%;
  /* height: 20%; */
  position: absolute;
  /* left: 0;
  right: 0;
  bottom: 0;
  top: 0; */
  margin: auto;
  background-color: #ffffff;
}
.popup_download_center {
  text-align: center;
}
.popup_download_center img {
  width: 100%;
}
.turnoff {
  width: 40px;
  height: 40px;
  border: solid #ffffff 1px;
  position: absolute;
  bottom: -25%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 40px;
  color: #ffffff;
}
.hover1:hover {
  cursor: pointer;
}
.bottom{
  width: 100%;
}
.center{
  width: 100%;

}
@media screen and (max-width: 900px) {
.popup_download{
  width:50%
}
}
</style>
