<template>
  <div class="nav">
   
    <div class="nav-icon">
      <img
        src="../assets/images/aipaofanlogo.png"
        alt=""
        @click="toindex"
        class="hover1"
      />
               <div class="caidan-z">
      <div class="iconfont icon-caidan caidan" @click="navshow"></div>
      <div class="caidan-nav" :style="`display:${navdisplay}`">
        <ul @click="navDown">
          <li  @click="downloadApp(5)">微信小程序</li>
          <li @click="downloadApp(4)">微信公众号</li>
         <li @click="downloadApp(6)">支付宝小程序</li>
          <!-- <li><a href="https://weibo.com/u/7755972606" style="color:#ffffff" target="_blank">支付宝小程序</a> </li> -->
        <li @click="downloadApp(3)">联系我们</li>
          <li>
            <router-link to="/aboutUs" style="color: #ffffff"
              >关于我们</router-link
            >
          </li>
          <li>
            <router-link to="/topNews" style="color: #ffffff"
              >爱泡饭资讯</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    </div>

    <div class="nav_right">
      <div class="right_ul">
        <div @click="downloadApp(5)" class="ul_li">微信小程序</div>
        <div @click="downloadApp(4)"  class="ul_li">微信公众号</div>
         <div @click="downloadApp(6)"  class="ul_li">支付宝小程序</div>
        <!-- <div class="ul_li"><a href="https://weibo.com/u/7755972606" style="color:#ffffff" target="_blank">微博</a> </div> -->
         <div @click="downloadApp(3)" class="ul_li">联系我们</div>
        <div class="about-us">
          <div class="about-us-text  ul_li" style="margin-left:0px">关于我们</div>

          <div class="about-us-box">
            <router-link to="/aboutUs">关于我们</router-link>
            <router-link to="/topNews">爱泡饭资讯</router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "topNav",
  data: () => {
    return {
      showImg: 1,
      navdisplay: "none",
    };
  },
  methods: {
    // 下载二维码显示
    downloadApp(a) {
      this.$bus.$emit("download", a);
    },
    //回到首页
    toindex() {
      this.$router.push({ name: "index" });
    },
    navshow() {
      this.navdisplay = this.navdisplay == "none" ? "flex" : "none";
    },
    navDown(){
      this.navdisplay =" none"
    }
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  background-color: #4cbcbc;
  justify-content: space-between;
  height: 70px;
}
.nav-icon {

  margin-left: 10%;
}
.nav-icon img {
  width: 163px;
  height: 70px;
}

.nav_right {
  margin-right: 10%;
  width: 550px;
}
.right_ul {
  display: flex;
  height: 100%;
  width: 100%;
 justify-content: center;
 align-items: center;
}
.right_ul div {
  /* margin: 20px 20px 0 20px; */
  font-size: 18px;
  cursor: pointer;
  margin-left: 20px;
  color: #ffffff;
}
.ul_li:hover {
  border-bottom: solid #f39c12 2px;
}
.about-us {
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  /* margin-bottom:20px ; */

}
.about-us-box {
  position: absolute;
  top: 70px;
  left: -30px;
  background-color: rgb(253, 249, 249);
  width: 100px;
  height: 80px;
  font-size: 15px;
  color: #040404;

  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: none;
  box-shadow: 2px 5px 5px #ced6e0;
  z-index: -99;
}
.about-us-text{
  margin-left: 0;
}
.about-us-box::before {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  position: absolute;
  top: -7px;
  transform: rotate(45deg);
}
.about-us:hover .about-us-box {
  display: flex;
}
.caidan-z:hover .caidan-nav {
  display: flex;
}

.caidan {
  font-size: 50px;
  line-height: 70px;
  margin-right: 100px;
}
.caidan-z {
  display: none;
}
.caidan-nav {
  background-color: hsl(0, 0%, 2%, 0.8);
  width: 100%;
  height: 340px;
  position: absolute;
  top: 70px;
  left: 0;
  color: #ffffff;
  justify-content: center;
  /* display: none; */
}
.caidan-nav ul{
width: 100%;
}
.caidan-nav ul li {
  margin-top: 20px;
  font-size: 18px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
/* .caidan-nav ul li:hover {
  background-image: linear-gradient(
    to right,
    hsl(0, 0%, 2%, 0.8),
    hsl(61, 93%, 48%, 0.8),
    hsl(0, 0%, 2%, 0.8)
  );
} */

@media screen and (min-width: 1000px) {
.nav-icon{
  width: 300px;
}
}
@media screen and (max-width: 1000px) {
  .nav{
    height: 50px;
  }
  .nav-icon{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
   .nav-icon img{
     width: 116px;
     height: 50px;
   }
  .nav_right {
    display: none;
  }
  .caidan{
    font-size: 40px;
    line-height: 50px;
    margin-right: 40px;
    z-index: 9999;
  }
  .caidan-z {
    display: block;
    z-index: 9999;
  }
  .caidan-nav{
    top: 50px;
  }
  /* .caidan-nav{
   display: flex;
} */
}
</style>